import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/LoginView.vue'),
        meta: {
            requiresGuest: true,
            isSinglePage: true,
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('@/views/RegisterView.vue'),
        meta: {
            requiresGuest: true,
            isSinglePage: true,
        },
    },
    {
        path: '/setup',
        name: 'setup',
        component: () => import('@/views/SetupView.vue'),
        meta: {
            requiresAuth: true,
            isSinglePage: true,
        },
    },

    {
        path: '/settings',
        name: 'settings',
        component: () => import('@/views/SettingsView.vue'),
        meta: {
            requiresAuth: true,
            isSinglePage: false,
        },
    },

    {
        path: '/media',
        name: 'media',
        component: () => import('@/views/MediaView.vue'),
        meta: {
            requiresAuth: true,
            isSinglePage: false,
        },
    },
    {
        path: '/statuses',
        name: 'statuses',
        component: () => import('@/views/StatusView.vue'),
        meta: {
            requiresAuth: true,
            isSinglePage: false,
        },
    },

    {
        path: '/customers',
        name: 'customers',
        component: () => import('@/views/CustomersView.vue'),
        meta: {
            requiresAuth: true,
            isSinglePage: false,
        },
    },
    {
        path: '/customers/:id',
        name: 'customer_detail',
        component: () => import('@/views/CustomerDetailView.vue'),
        meta: {
            requiresAuth: true,
            isSinglePage: false,
        },
    },
    {
        path: '/orders/:id',
        name: 'order_detail',
        component: () => import('@/views/OrderView.vue'),
        meta: {
            requiresAuth: true,
            isSinglePage: false,
        },
    },
    {
        path: '/invoice/:id',
        name: 'invoice_view',
        component: () => import('@/views/InvoiceView.vue'),
        meta: {
            requiresAuth: false,
            isSinglePage: true,
        },
    },
    {
        path: '/orders',
        name: 'orders',
        component: () => import('@/views/OrdersView.vue'),
        meta: {
            requiresAuth: true,
            isSinglePage: false,
        },
    },

    {
        path: '/invoices',
        name: 'invoices',
        component: () => import('@/views/InvoicesView.vue'),
        meta: {
            requiresAuth: true,
            isSinglePage: false,
        },
    },

    {
        path: '/quotation',
        name: 'quotation',
        component: () => import('@/views/QuotationsView.vue'),
        meta: {
            requiresAuth: true,
            isSinglePage: false,
        },
    },
    {
        path: '/quick-quote',
        name: 'quick-quote',
        component: () => import('@/views/QuickQuotationView.vue'),
        meta: {
            requiresAuth: true,
            isSinglePage: false,
        },
    },

    {
        path: '/',
        name: 'dashboard',
        component: () => import('@/views/DashboardView.vue'),
        meta: {
            requiresAuth: true,
            isSinglePage: false,
        },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

export default router
