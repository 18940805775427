
    import BaseClass from '@/base'
    import { Component, Prop, Watch } from 'vue-property-decorator'
    import firebase from '@/plugins/firebase'

    @Component
    export default class OrdersCardComponent extends BaseClass {
        @Prop({ type: Object }) readonly customer!: firebase.firestore.DocumentData
        copyOrder: null | firebase.firestore.DocumentData = null
        selectedOrders = []
        orderDialog = false
        orders: firebase.firestore.DocumentData[] = []
        search = ''
        loaded = false
        invoiceDialog = false
        invoiceStatus = 'Unpaid'
        invoiceDiscount = '0'
        headers = [
            {
                text: 'Order Number',
                value: 'orderId',
            },
            {
                text: 'Date',
                value: 'createdAt',
            },
            {
                text: 'Media',
                value: 'media',
            },
            {
                text: 'Quantity',
                value: 'quantity',
            },

            {
                text: 'Width/Height',
                value: 'widthAndHeight',
            },
            {
                text: 'Price',
                value: 'price',
            },

            {
                text: 'Status',
                value: 'status',
                width: '250px',
            },
            {
                text: '',
                value: 'tags',
            },
            {
                text: '',
                value: 'actions',
                width: '200px',
            },
        ]

        @Watch('orderDialog')
        onOrderDialogChanged(dialog: boolean) {
            if (!dialog) {
                this.copyOrder = null
            }
        }

        async mounted() {
            await this.$bind(
                'orders',
                this.firebase
                    .firestore()
                    .collection('orders')
                    .where('companyId', '==', this.$account.company.id)
                    .where('customer.id', '==', this.customer.id)
            )

            this.loaded = true
        }

        getMediaName(id: string) {
            const media = this.getMediaFromId(id)
            if (!media) {
                return '--'
            }
            return media.name
        }

        getTagColor(text: string) {
            const tag = this.getTagFromName(text)
            if (!tag) {
                return 'grey'
            }
            return tag.color
        }

        async reorder(order: firebase.firestore.DocumentData) {
            this.copyOrder = order
            console.log(this.copyOrder)
            this.orderDialog = true
        }

        async createInvoice() {
            this.loading = true
            try {
                await this.firebase
                    .firestore()
                    .collection(`invoices`)
                    .add({
                        customer: {
                            id: this.customer.id,
                            name: this.customer.name,
                            email: this.customer.email,
                            phoneNumber: this.customer.phoneNumber,
                            address: this.customer.address,
                        },
                        //status: this.invoiceStatus,
                        status: 'Unpaid',
                        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                        companyId: this.$account.companyId,
                        ordersArray: this.selectedOrders.map((order: any) => order.id),
                        orders: this.selectedOrders,
                        amount: this.selectedOrders.reduce((acc, order: any) => acc + order.price, 0),
                        balance:
                            this.selectedOrders.reduce((acc, order: any) => acc + order.price, 0) -
                            (Number.parseFloat(this.invoiceDiscount) || 0),
                        discount: Number.parseFloat(this.invoiceDiscount) || 0,
                    })

                this.selectedOrders = []
                this.invoiceStatus = 'Unpaid'
                this.invoiceDialog = false

                this.snackMessage(`Invoice created.`)
            } catch (error: unknown) {
                if (error instanceof Error) if (error instanceof Error) this.snackMessage(error.message, 'error')
            }

            this.loading = false
        }

        generateInvoice() {
            this.invoiceDialog = true
        }

        async updateStatus(item: firebase.firestore.DocumentData) {
            await this.firebase.firestore().doc(`orders/${item.id}`).update({ statusId: item.statusId })
            this.snackMessage(`Status updated.`)
        }
    }
