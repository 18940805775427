import firebase from '@/plugins/firebase'
import store from '@/store'

export default class Account {
    readonly id!: string
    readonly user!: firebase.User

    constructor(user?: firebase.User) {
        if (user) {
            this.id = user.uid
            this.user = user
        }
    }

    get isLoggedIn(): boolean {
        return !!firebase.auth().currentUser
    }

    get firstName(): string {
        return store.getters['me']?.firstName || ''
    }

    get lastName(): string {
        return store.getters['me']?.lastName || ''
    }

    get photoURL(): string | null | undefined {
        return firebase.auth()?.currentUser?.photoURL
    }

    get country(): string {
        return store.getters['me']?.country || ''
    }

    get email(): string {
        return store.getters['me']?.email || this.user?.email || ''
    }

    get userData(): firebase.firestore.DocumentData {
        return store.getters['me']
    }

    get companyId(): string {
        return store.getters['me']?.companyId || null
    }

    get company(): firebase.firestore.DocumentData {
        return store.getters['company/data']
    }

    get companyMedia(): firebase.firestore.DocumentData[] {
        return store.getters['media/data']
    }

    get companyStatuses(): firebase.firestore.DocumentData[] {
        return store.getters['statuses/data']
    }

    public async init(): Promise<void> {
        await store.dispatch('bindMe', this.id)
        await Promise.all([
            store.dispatch('company/bind', this.companyId),
            store.dispatch('media/bind', this.companyId),
            store.dispatch('statuses/bind', this.companyId),
        ])
    }

    public async login(email: string, password: string): Promise<firebase.auth.UserCredential> {
        return firebase.auth().signInWithEmailAndPassword(email, password)
    }

    public async logout(): Promise<void> {
        return firebase.auth().signOut()
    }
}
