import moment from 'moment'
import Vue from 'vue'
import store from './store'

Vue.filter('numberFormat', (value: number) => {
    return value.toLocaleString()
})

Vue.filter('moneyFormat', (value: number) => {
    if (value === undefined || value === null) {
        return ''
    }
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: store.getters['company/data']?.currency || 'USD',
    }).format(value)
})

Vue.filter('dateFromNow', (value: firebase.default.firestore.Timestamp) => {
    if (!value) {
        return ''
    }
    return moment(value.toDate()).fromNow()
})

Vue.filter('date', (value: firebase.default.firestore.Timestamp) => {
    if (!value) {
        return ''
    }
    return moment(value.toDate()).format('MMMM DD, YYYY')
})

Vue.filter('time', (value: firebase.default.firestore.Timestamp) => {
    if (!value) {
        return ''
    }
    return moment(value.toDate()).format('h:mm a')
})

Vue.filter('dateTime', (value: firebase.default.firestore.Timestamp) => {
    if (!value) {
        return ''
    }
    return moment(value.toDate()).format('MMMM DD, YYYY @ h:mm a')
})

Vue.filter('orderNumber', (value: number) => {
    if (!value) {
        return ''
    }
    return `#${value.toString().padStart(4, '0')}`
})
