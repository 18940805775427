import { required, numeric, double, email, max, min, regex, confirmed, between } from 'vee-validate/dist/rules'
import { extend, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('numeric', {
    ...numeric,
    message: '{_field_} must be a number',
})

extend('between', {
    ...between,
    message: '{_field_} must be between {min} and {max}',
})

extend('double', {
    ...double,
    message: '{_field_} must be a number',
})

extend('required', {
    ...required,
    message: '{_field_} can not be empty',
})

extend('min', {
    ...min,
    message: '{_field_} must be more than {length} characters',
})

extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
})

extend('regex', {
    ...regex,
    message: '{_field_} {_value_} does not match {regex}',
})

extend('email', {
    ...email,
    message: 'Invalid e-mail address',
})

extend('confirmed', {
    ...confirmed,
    message: '{_field_} does not match',
})
