import { Module } from 'vuex'
import { firestoreAction, vuexfireMutations } from 'vuexfire'
import firebase from 'firebase/app'

const baseState = {
    // myself (users/uid)
    data: [] as firebase.firestore.DocumentData[],
}

export default {
    state: baseState,
    mutations: {
        ...vuexfireMutations,
    },
    getters: {
        data: state => state.data,
    },
    actions: {
        bind: firestoreAction<typeof baseState, unknown>(({ bindFirestoreRef }, companyId) => {
            return bindFirestoreRef(
                'data',
                firebase.firestore().collection(`statuses`).where('companyId', '==', companyId).orderBy('order', 'asc')
            )
        }),
        unbind: firestoreAction(({ unbindFirestoreRef }) => {
            unbindFirestoreRef('data')
        }),
    },
} as Module<typeof baseState, any>
