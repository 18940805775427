
    import BaseClass from '@/base'
    import { Component, Prop, PropSync, VModel } from 'vue-property-decorator'
    import firebase from '@/plugins/firebase'

    @Component
    export default class FileSelectorDialogComponent extends BaseClass {
        @PropSync('enabled', { type: Boolean, default: false }) dialog!: boolean
        @Prop() readonly customerId!: firebase.firestore.DocumentData
        @Prop() readonly files!: firebase.firestore.DocumentData[]
        @VModel({ type: Array }) fileIds!: Array<string>

        loaded = false

        toggleFile(file: firebase.firestore.DocumentData) {
            if (this.fileIds.includes(file.id)) {
                this.fileIds = this.fileIds.filter((id: string) => id !== file.id)
            } else {
                this.fileIds.push(file.id)
            }
        }
    }
