import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { camelCase, upperFirst } from 'lodash'
import firebase from '@/plugins/firebase'
import Account from '@/models/account'
import './filters'
import pdf from 'vue-pdf'

Vue.config.productionTip = false
Vue.prototype.$account = new Account()
Vue.component('v-pdf', pdf)
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { firestorePlugin } from 'vuefire'

Vue.use(firestorePlugin)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
const requireComponent = require.context('./components', true, /[A-Z]\w+\.(vue|js)$/)

requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName)
    const componentName = upperFirst(
        camelCase(
            fileName
                .split('/')
                .pop()
                ?.replace(/\.\w+$/, '')
        )
    )

    Vue.component(componentName, componentConfig.default || componentConfig)
})

router.beforeEach((to, from, next) => {
    const requiresGuest = !!to.matched.some(x => x.meta.requiresGuest)
    const isLoggedIn = !!firebase.auth().currentUser
    const hasCompany = !!store.getters['me']?.companyId

    Vue.prototype.$isSinglePage = !!to.matched.some(x => x.meta.isSinglePage)

    if (to.name === 'invoice_view') {
        next()
    } else if (!isLoggedIn && !requiresGuest && to.path !== '/login') {
        next('/login')
    } else if (isLoggedIn && !hasCompany && to.path !== '/setup') {
        next('/setup')
    } else if (isLoggedIn && hasCompany && to.path === '/setup') {
        next('/')
    } else if (isLoggedIn && requiresGuest) {
        next('/')
    } else {
        next()
    }
})

let app: object

firebase.auth().onAuthStateChanged(async user => {
    await (async function () {
        if (user !== null) {
            Vue.prototype.$account = new Account(user)
            await Promise.all([Vue.prototype.$account.init()])
        } else {
            Vue.prototype.$account.logout()
        }
    })()

    if (!app) {
        app = new Vue({
            router,
            store,
            vuetify,
            render: h => h(App),
        }).$mount('#app')
    }
})
