
    import BaseClass from '@/base'
    import { Component, Prop, PropSync, VModel } from 'vue-property-decorator'
    import firebase from '@/plugins/firebase'

    @Component
    export default class EditCustomerDialogComponent extends BaseClass {
        @PropSync('enabled', { type: Boolean, default: false }) dialog!: boolean
        @Prop(Boolean) readonly dontClear!: boolean
        @VModel({ type: Object }) customer!: firebase.firestore.DocumentData

        async save() {
            this.loading = true

            try {
                await this.firebase.firestore().doc(`customers/${this.customer.id}`).update({
                    name: this.customer.name,
                    address: this.customer.address,
                    email: this.customer.email,
                    phoneNumber: this.customer.phoneNumber,
                })

                this.dialog = false
                if (!this.dontClear) {
                    this.customer = {
                        id: '',
                        name: '',
                        address: '',
                        email: '',
                        phoneNumber: '',
                    }
                }

                this.snackMessage('Customer updated successfully!', 'success')
            } catch (error: unknown) {
                if (error instanceof Error) if (error instanceof Error) this.snackMessage(error.message, 'error')
            }
            this.loading = false
        }
    }
