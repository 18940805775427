
    import BaseClass from '@/base'
    import { Component, Prop } from 'vue-property-decorator'
    import firebase from '@/plugins/firebase'
    import moment from 'moment'

    @Component
    export default class ViewInvoicePartialComponent extends BaseClass {
        loaded = false
        saving = false
        @Prop({ type: Boolean }) readonly hasControls!: boolean
        @Prop({ type: String, default: '100%' }) readonly width!: string
        @Prop({ type: Boolean }) readonly standalone!: boolean
        @Prop({ type: String, default: '', required: true }) readonly invoiceId!: string

        customer: firebase.firestore.DocumentData = {}
        confirmDeleteDialog = false
        payDialog = false
        paymentAmount = ''
        payments: firebase.firestore.DocumentData[] = []
        invoice: firebase.firestore.DocumentData = {}
        imageBase64: { orderId: string; data: string }[] = []
        company: firebase.firestore.DocumentData = {}
        async mounted() {
            await Promise.all([
                this.$bind('invoice', this.firebase.firestore().doc(`invoices/${this.invoiceId}`)),
                this.$bind(
                    'payments',
                    this.firebase.firestore().collection('payments').where('invoiceId', '==', this.invoiceId).orderBy('createdAt', 'asc')
                ),
            ])
            const promises: any[] = []
            this.invoice.orders.forEach((order: any) => {
                promises.push(
                    this.base64Image(order.filesData[0].url).then((imageData: any) => {
                        this.imageBase64.push({
                            orderId: order.id,
                            data: imageData,
                        })
                    })
                )
            })

            await Promise.all(promises)

            this.loaded = true
        }

        async deleteInvoice() {
            this.loading = true
            try {
                await this.firebase.firestore().doc(`invoices/${this.invoiceId}`).delete()
                this.snackMessage('Invoice deleted')
                this.confirmDeleteDialog = false
                this.$root.$emit('HIDE_INVOICE_MODAL')
            } catch (error: unknown) {
                if (error instanceof Error) {
                    this.snackMessage(error.message, 'error')
                }
            }
            this.loading = false
        }

        getTagColor(text: string) {
            const tag = this.getTagFromName(text)
            if (!tag) {
                return 'grey'
            }
            return tag.color
        }

        async payInvoice() {
            this.loading = true
            try {
                await this.firebase
                    .firestore()
                    .collection('payments')
                    .add({
                        invoiceId: this.invoiceId,
                        companyId: this.$account.companyId,
                        customer: this.invoice.customer,
                        amount: Number.parseFloat(this.paymentAmount),
                        //     createdAt: moment().subtract(3, 'months').toDate(),
                        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                    })

                this.snackMessage('Payment added')
                this.payDialog = false
                this.paymentAmount = ''
            } catch (error: unknown) {
                if (error instanceof Error) {
                    this.snackMessage(error.message, 'error')
                }
            }
            this.loading = false
        }

        async removePayment(paymentId: string) {
            await this.firebase.firestore().doc(`payments/${paymentId}`).delete()
            this.snackMessage('Payment removed')
        }

        private async base64Image(url: string) {
            const data = await fetch(url)
            const blob = await data.blob()
            return new Promise(resolve => {
                const reader = new FileReader()
                reader.readAsDataURL(blob)
                reader.onloadend = () => {
                    const base64data = reader.result
                    resolve(base64data)
                }
            })
        }

        getImageData(id: string) {
            const image = this.imageBase64.find((image: any) => image.orderId === id)
            if (!image) {
                return ''
            }

            return image.data
        }
    }
