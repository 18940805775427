
    import BaseClass from '@/base'
    import { Component, VModel, Watch } from 'vue-property-decorator'

    type Tag = {
        text: string
        color: string
    }

    @Component
    export default class TagSelectorComponent extends BaseClass {
        @VModel({ type: Array }) tags!: Array<Tag>

        activator = null
        attach = null
        colors = [
            'green',
            'purple',
            'indigo',
            'cyan',
            'teal',
            'orange',
            'red',
            'blue',
            'pink',
            'deep-purple',
            'light-blue',
            'light-green',
            'lime',
            'yellow',
            'amber',
            'orange',
            'deep-orange',
            'brown',
            'blue-grey',
        ]
        search = null
        nonce = 1

        mounted() {
            // remove used colors
            const usedColors: string[] = this.$account.company.tags.map((i: { color: string }) => i.color)
            usedColors.forEach(color => {
                if (this.colors.includes(color)) {
                    this.colors.splice(this.colors.indexOf(color), 1)
                }
            })
        }

        @Watch('tags')
        onTagsChanged(tags: Array<Tag>, prev: Array<Tag>) {
            if (tags.length === prev.length) return

            this.tags = tags.map(v => {
                if (typeof v === 'string') {
                    v = {
                        text: v,
                        color: this.colors[this.nonce - 1],
                    }

                    this.tags.push(v)

                    this.nonce++
                }

                return v
            })
        }

        filter(item: any, queryText: string, itemText: string) {
            if (item.header) {
                return false
            }

            const hasValue = (val: any) => (val != null ? val : '')

            const text = hasValue(itemText)
            const query = hasValue(queryText)

            return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1
        }
    }
