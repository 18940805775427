
    import BaseClass from '@/base'
    import { Component, Prop, PropSync } from 'vue-property-decorator'
    import firebase from '@/plugins/firebase'

    @Component
    export default class PreviewFileComponent extends BaseClass {
        @PropSync('enabled', { type: Boolean, default: false }) dialog!: boolean
        @Prop(Object) file!: firebase.firestore.DocumentData
        downloading = false

        deleteFile() {
            this.loading = true
            this.disableButtons = true
            try {
                this.firebase.firestore().doc(`files/${this.file.id}`).delete()
                this.snackMessage('File deleted successfully.')
            } catch (error: unknown) {
                if (error instanceof Error) this.snackMessage('An error occured while trying to delete this file.', 'error')
            }
            this.loading = false
            this.disableButtons = false
        }

        async download() {
            this.downloading = true
            try {
                const file = await (await fetch(this.file.url)).arrayBuffer()

                const url = window.URL.createObjectURL(new Blob([file]))
                const fakeLink = document.createElement('a')

                fakeLink.href = url
                fakeLink.setAttribute('download', `${this.file.fileName}`)
                document.body.appendChild(fakeLink)
                this.downloading = false
                fakeLink.click()
            } catch (error: unknown) {
                if (error instanceof Error) this.snackMessage(error.message, 'error')
            }
            this.downloading = false
        }
    }
