import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/app-check'
import 'firebase/functions'
import 'firebase/storage'

import * as config from '@/../config.json'

firebase.initializeApp(config.firebase)
const appCheck = firebase.appCheck()
appCheck.activate('6LfCGP8fAAAAAMRs06qAydeDHX1XmTDE-_EB_xwG', true)

export default firebase
