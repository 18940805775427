
    import BaseClass from '@/base'
    import { Component, Prop, Watch } from 'vue-property-decorator'
    import firebase from '@/plugins/firebase'

    @Component
    export default class InvoiceCardComponent extends BaseClass {
        @Prop({ type: String }) readonly customerId!: string
        loaded = false
        invoices: firebase.firestore.DocumentData[] = []
        viewInvoiceDialog = false
        viewInvoiceId = ''

        @Watch('viewInvoiceDialog')
        onChange(dialog: boolean) {
            if (!dialog) {
                this.viewInvoiceId = ''
            }
        }

        headers = [
            {
                text: 'Invoice Number',
                value: 'invoiceId',
            },
            {
                text: 'Date',
                value: 'createdAt',
            },
            {
                text: '# of Orders',
                value: 'numberOfOrders',
            },
            {
                text: 'Amount',
                value: 'amount',
            },
            {
                text: 'Balance',
                value: 'balance',
            },
            {
                text: '',
                value: 'actions',
            },
        ]
        async mounted() {
            this.$root.$on('HIDE_INVOICE_MODAL', () => {
                this.viewInvoiceDialog = false
            })
            await this.$bind(
                'invoices',
                this.firebase
                    .firestore()
                    .collection(`invoices`)
                    .where('companyId', '==', this.$account.company.id)
                    .where('customer.id', '==', this.customerId)
                    .orderBy('createdAt', 'desc')
            )
            this.loaded = true
        }

        view(item: firebase.firestore.DocumentData) {
            this.viewInvoiceId = item.id
            this.viewInvoiceDialog = true
        }
    }
