import { Vue } from 'vue-property-decorator'
import store from '@/store'
import firebase from '@/plugins/firebase'
import '@/constants/validation'
declare type SnackType = 'error' | 'success' | undefined

export default class BaseClass extends Vue {
    protected readonly store = store
    protected readonly firebase = firebase
    loading = false
    disableButtons = false

    snackMessage(message: string, type?: SnackType) {
        store.dispatch('snackbar/setSnack', {
            message: message,
            type: type,
        })
    }

    getMediaFromId(id: string) {
        const medias = store.getters['media/data']
        const media = medias.find((media: firebase.firestore.DocumentData) => media.id === id)

        return media
    }

    getStatusFromId(id: string) {
        const statuses = store.getters['statuses/data']
        const status = statuses.find((status: firebase.firestore.DocumentData) => status.id === id)

        return status
    }

    getTagFromName(name: string) {
        const company = store.getters['company/data']
        const tags = company.tags
        const tag = tags.find((tag: firebase.firestore.DocumentData) => tag.text === name)

        return tag
    }

    isImage(file: firebase.firestore.DocumentData) {
        const images = ['image/jpeg', 'image/png', 'image/gif']
        return images.includes(file.fileType)
    }

    isPdf(file: firebase.firestore.DocumentData) {
        if (file.fileType === 'application/pdf') {
            return true
        }
        return false
    }

    getStatusColor(id: string) {
        const status = this.getStatusFromId(id)
        if (!status) {
            return '--'
        }
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(status.color)
        if (!result) {
            return 'grey'
        }

        return `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, 0.3)`
    }
}
