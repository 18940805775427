
    import BaseClass from '@/base'
    import { Component, Prop } from 'vue-property-decorator'
    import firebase from '@/plugins/firebase'

    @Component
    export default class PreviewOrderComponent extends BaseClass {
        @Prop({ type: Number, default: 100 }) readonly width!: number
        @Prop({ type: String, default: '' }) readonly imageUrl!: string
        @Prop({ type: String }) readonly orderId!: string
        @Prop({ type: Object }) readonly order!: firebase.firestore.DocumentData
        image = ''
        isNotAnImage = false
        type = ''
        loaded = false

        async mounted() {
            if (this.imageUrl) {
                this.image = this.imageUrl
                this.loaded = true
                return
            }

            let file: firebase.firestore.DocumentData = {}

            if (this.orderId) {
                const orderRef = await firebase.firestore().doc(`orders/${this.orderId}`).get()

                if (!orderRef.exists) {
                    this.loaded = true
                    return
                }

                file = orderRef.data()!.filesData[0]
            } else if (this.order) {
                file = this.order.filesData[0]
            }

            if (this.isImage(file)) {
                this.image = file.url
                this.loaded = true
                this.type = 'image'
                return
            }

            if (this.isPdf(file)) {
                this.image = file.url
                this.loaded = true
                this.type = 'pdf'
                return
            }

            this.isNotAnImage = true
            this.type = file.fileType
            this.loaded = true
        }
    }
