
    import BaseClass from '@/base'
    import { Component } from 'vue-property-decorator'

    @Component
    export default class SnackbarComponent extends BaseClass {
        show = false
        message = ''
        color = 'grey darken-3'
        created() {
            this.$store.watch(
                state => state.snackbar.message,
                () => {
                    const msg = this.$store.state.snackbar.message

                    if (msg) {
                        this.show = true
                        this.message = msg
                        this.color = this.$store.state.snackbar.type === 'error' ? 'red darken-3' : 'grey darken-3'
                        this.$store.dispatch('snackbar/setSnack', {
                            message: '',
                            type: '',
                        })
                    }
                }
            )
        }
    }
