
    import BaseClass from '@/base'
    import { Component } from 'vue-property-decorator'

    @Component
    export default class AppNavigationComponent extends BaseClass {
        async signOut() {
            await this.$account.logout()
            window.location.href = '/'
        }
    }
