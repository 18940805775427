import Vue from 'vue'
import Vuex from 'vuex'
import { firestoreAction, vuexfireMutations } from 'vuexfire'
import modules from './modules'
import firebase from '@/plugins/firebase'

Vue.use(Vuex)

const baseState = {
    // myself (users/uid)
    me: {} as firebase.firestore.DocumentData,
}

export default new Vuex.Store({
    state: baseState,
    getters: {
        me: state => (state as any).me,
    },
    mutations: {
        ...vuexfireMutations,
    },
    actions: {
        // @ts-expect-error: firestoreAction is not a function
        bindMe: firestoreAction<typeof baseState, unknown>(({ bindFirestoreRef }, uid) => {
            return bindFirestoreRef('me', firebase.firestore().doc(`users/${uid}`))
        }),
        unbindMe: firestoreAction(({ unbindFirestoreRef }) => {
            unbindFirestoreRef('me')
        }),
    },
    modules: modules,
})
