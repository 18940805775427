
    import BaseClass from '@/base'
    import { Component, Prop, PropSync, Watch } from 'vue-property-decorator'
    import firebase from '@/plugins/firebase'
    import { v4 as uuidv4 } from 'uuid'

    @Component
    export default class OrderDialogComponent extends BaseClass {
        loaded = false
        $refs!: {
            form: HTMLFormElement
        }
        @PropSync('enabled', { type: Boolean, default: false }) dialog!: boolean
        @Prop(Object) readonly customer!: firebase.firestore.DocumentData
        @Prop({ type: String, required: false, default: 'create' }) readonly mode!: string
        @Prop({ type: Object, required: false }) readonly order!: firebase.firestore.DocumentData
        @Prop({ type: Object, required: false }) readonly copyOrder!: firebase.firestore.DocumentData
        saving = false
        fileSelectorDialog = false
        customerFiles: firebase.firestore.DocumentData[] = []
        selectedLibraryFiles: string[] = []
        title = ''
        media: firebase.firestore.DocumentData = {}
        status: firebase.firestore.DocumentData = {}
        width = ''
        height = ''
        quantity = ''
        price = ''
        notes = ''
        tags = []

        files: {
            file: File
            url: string
            progress: number
            id: string
        }[] = []

        @Watch('enabled')
        onDialogEnabled(enabled: boolean) {
            if (!enabled) {
                return
            }
            if (this.copyOrder) {
                this.title = this.copyOrder.title
                this.media = this.getMediaFromId(this.copyOrder.mediaId)
                this.status = this.getStatusFromId(this.copyOrder.statusId)
                this.width = this.copyOrder.width.toString()
                this.height = this.copyOrder.height.toString()
                this.quantity = this.copyOrder.quantity.toString()
                this.price = this.copyOrder.price.toString()
                this.notes = this.copyOrder.notes
                this.tags = this.copyOrder.tags.map((tag: string) => {
                    return {
                        text: tag,
                        color: this.getTagFromName(tag).color,
                    }
                })
                this.selectedLibraryFiles = this.copyOrder.files
            }
        }

        async handleUpload(e: Event) {
            const target = e.target as HTMLInputElement
            const files = target.files

            if (!files?.length) {
                return
            }
            this.files = []

            for (const file of files) {
                this.files.push({
                    id: uuidv4(),
                    file: file,
                    url: URL.createObjectURL(file),
                    progress: 0,
                })
            }
        }

        async save() {
            this.saving = true
            this.loading = true
            try {
                const tags = [...this.$account.company.tags, ...this.tags]
                const keys = ['text']
                const filteredTags = tags.filter(
                    (
                        s => (o: { [x: string]: any }) =>
                            (k => !s.has(k) && s.add(k))(keys.map(k => o[k]).join('|'))
                    )(new Set())
                )
                let orderRef: firebase.firestore.DocumentReference
                const firestorePromises: any[] = []
                const dataStore = {
                    mediaId: this.media.id,
                    statusId: this.status.id,
                    width: Number.parseFloat(this.width),
                    height: Number.parseFloat(this.height),
                    quantity: Number.parseInt(this.quantity),
                    price: Number.parseFloat(this.price),
                    notes: this.notes,
                    files: [...this.selectedLibraryFiles, ...this.files.map(i => i.id)],
                    title: this.title || '',
                    tags: this.tags.map((e: any) => e.text) || [],
                    filesData: this.customerFiles
                        .filter((i: any) => this.selectedLibraryFiles.includes(i.id))
                        .map(x => {
                            return {
                                id: x.id,
                                url: x.url,
                                fileName: x.fileName,
                                fileSize: x.fileSize,
                                fileType: x.fileType,
                            }
                        }),
                }
                if (this.mode === 'create') {
                    orderRef = this.firebase.firestore().collection('orders').doc()
                    firestorePromises.push(
                        orderRef.set({
                            companyId: this.$account.companyId,
                            customer: {
                                id: this.customer.id,
                                name: this.customer.name,
                                email: this.customer.email,
                            },

                            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                            ...dataStore,
                        })
                    )
                } else {
                    orderRef = this.firebase.firestore().doc(`orders/${this.order.id}`)
                    firestorePromises.push(orderRef.update(dataStore))
                }

                await Promise.all([
                    // Store new tags in company
                    this.firebase.firestore().doc(`companies/${this.$account.companyId}`).update({
                        tags: filteredTags,
                    }),
                    firestorePromises,
                ])

                // store files on storage

                const storage = this.firebase.storage().ref()
                const promises: any[] = []
                this.files.forEach(file => {
                    const extension = file.file.name.split('.').pop()
                    const task = storage.child(`${this.$account.company.id}/${this.customer.id}/${file.id}.${extension}`).put(file.file)
                    task.on('state_changed', snapshot => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        file.progress = progress
                    })

                    promises.push(task)
                })

                // upload all files
                await Promise.all(promises)

                this.snackMessage(`Order ${this.mode === 'create' ? 'created' : 'updated'} successfully`)
                this.dialog = false

                this.title = ''
                this.media = {}
                this.status = {}
                this.width = ''
                this.height = ''
                this.quantity = ''
                this.price = ''
                this.notes = ''
                this.tags = []
                this.files = []
                this.$refs.form.reset()
                if (this.mode === 'edit') {
                    this.$emit('ORDER_UPDATED')
                }
            } catch (error: unknown) {
                if (error instanceof Error) this.snackMessage(error.message, 'error')
            }
            this.saving = false
            this.loading = false
        }

        async mounted() {
            this.status = this.$account.companyStatuses[0]
            await this.$bind(
                'customerFiles',
                this.firebase
                    .firestore()
                    .collection('files')
                    .where('companyId', '==', this.$account.companyId)
                    .where('customerId', '==', this.customer.id)
            )

            if (this.mode === 'edit') {
                this.title = this.order.title
                this.media = this.getMediaFromId(this.order.mediaId)
                this.status = this.getStatusFromId(this.order.statusId)
                this.width = this.order.width.toString()
                this.height = this.order.height.toString()
                this.quantity = this.order.quantity.toString()
                this.price = this.order.price.toString()
                this.notes = this.order.notes
                this.tags = this.order.tags.map((tag: string) => {
                    return {
                        text: tag,
                        color: this.getTagFromName(tag).color,
                    }
                })
                this.selectedLibraryFiles = this.order.files
            }

            this.loaded = true
        }

        getFileFromId(id: string) {
            return this.customerFiles.find(i => i.id === id)
        }

        removeLibraryFile(id: string) {
            this.selectedLibraryFiles = this.selectedLibraryFiles.filter(i => i !== id)
        }
    }
