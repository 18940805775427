<template>
    <Bar
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :css-classes="cssClasses"
        :width="width"
        :height="height"
    />
</template>

<script>
    import { Bar } from 'vue-chartjs/legacy'
    import { Chart as ChartJS, Title, Tooltip, BarElement, CategoryScale, LinearScale } from 'chart.js'

    ChartJS.register(Title, Tooltip, BarElement, CategoryScale, LinearScale)

    export default {
        name: 'BarChart',
        components: { Bar },
        props: {
            chartId: {
                type: String,
                default: 'bar-chart',
            },
            datasetIdKey: {
                type: String,
                default: 'label',
            },
            width: {
                type: Number,
                default: 200,
            },
            height: {
                type: Number,
                default: 200,
            },
            cssClasses: {
                default: '',
                type: String,
            },

            chartData: {
                type: Object,
                default: () => ({}),
            },
            chartOptions: {
                type: Object,
                default: () => ({}),
            },
        },
    }
</script>
