
    import BaseClass from '@/base'
    import { Component, Watch } from 'vue-property-decorator'
    import moment from 'moment'

    @Component
    export default class App extends BaseClass {
        async mounted() {
            const startOfDay = moment().startOf('day')
            const now = moment()
            const companies: {
                id: string
                earnings: number
            }[] = []

            const paymentsTodayRef = await this.firebase
                .firestore()
                .collection(`payments`)
                .where('createdAt', '>=', startOfDay.toDate())
                .where('createdAt', '<=', now.toDate())

                .get()
        }
    }
