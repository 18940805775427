import camelCase from 'lodash/camelCase'

const requireModule = require.context('.', false, /\.ts$/)
const modules: {
    [index: string]: Record<string, unknown>
} = {}

requireModule
    .keys()
    .filter(i => i !== './index.ts')
    .forEach(fileName => {
        const moduleName = camelCase(fileName.replace(/(\.\/|\.ts)/g, ''))

        modules[moduleName] = {
            namespaced: true,
            ...requireModule(fileName).default,
        }
    })
export default modules
